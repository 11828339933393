import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import ReactTypingEffect from 'react-typing-effect';

const Header = () => {
  const { intro } = useContext(PortfolioContext);
  const { title, name, subtitle, blink, cta } = intro;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="intro" className="jumbotron">
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h1 className="intro-title">
            {title}{' '}
            {isMobile ? <br /> : ''}
            <span className="text-color-single">{name}.</span>
            <br />
            <span className="intro-secondary-text">
              {subtitle}
              {isMobile ? <br /> : ''}
              <ReactTypingEffect
                text={blink}
                eraseDelay={2000}
                typingDelay={1500}
                cursor='_'
              />
            </span>
          </h1>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <p className="intro-cta">
            <span className="cta-btn cta-btn--intro">
              <Link to="about" smooth duration={1000}>
                {cta}
              </Link>
            </span>
          </p>
        </Fade>
      </Container>
    </section>
  );
};

export default Header;
