import React, { useState, useEffect } from 'react';
import Intro from './Intro/Intro';
import About from './About/About';
import Tech from './Projects/Projects';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';

import { PortfolioProvider } from '../context/context';

import { introData, aboutData, projectsData, contactData, footerData } from './mock/data';

function Home() {
  const [intro, setIntro] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setIntro({ ...introData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ intro, about, projects, contact, footer }}>
      <Intro />
      <About />
      <Tech />
      <Contact />
      <Footer />
    </PortfolioProvider>
  );
}

export default Home;
