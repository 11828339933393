// HEAD DATA
export const headData = {
  title: 'Arnab Saha | Developer',
  lang: 'en',
  description: 'Welcome to my portfolio page'
};

// INTRO DATA
export const introData = {
  title: 'Hi, I\'m ',
  name: 'Arnab Saha',
  subtitle: 'I\'m a Web Developer & ',
  blink: ['Programmer.', 'Designer.', 'Traveler.', 'Vlogger.', 'Football Enthusiast.', 'Marie Biscuit Lover.'],
  cta: 'Know more about me ',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'I am a Full stack Developer, currently living in Bengaluru, KA, India.',
  paragraphTwo: 'Currently, I am working as a Web Engineer at a product based company. I work towards making a better web; one that is fast, easy to use, beautiful, accessible to all and is build with best practices',
  paragraphThree: 'My main experience is in full stack front-end development but I also have a serious passion for UI effects, animations and creating intuitive, dynamic user experiences.',
  paragraphFour: 'I am experienced in building systems that implement business logic and interact with server-side technology via APIs and tools. I have worked with teams to develop multiple web apps from scratch which are doing really well in terms of the User Experience and generating revenue for the company.',
  resume: ''
};

// PROJECTS DATA
export const projectsData = [
  {
    id: '1',
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: '2',
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: '3',
    img: 'project.jpg',
    title: '',
    info: '',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  }
];

// CONTACT DATA
export const contactData = {
  cta: 'If you want to reach me,',
  btn: 'say hello at ',
  email: 'arnab10saha@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: '1',
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/arnabsaha',
    },
    {
      id: '2',
      name: 'github',
      url: 'https://github.com/geekychanakya',
    },
    {
      id: '3',
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCDYfcmJmc09aj-2G38XJj3A/featured',
    }
  ]
};
